<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        class="card-body"
      >
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col md="6">
              <b-form-group label="Название">
                <b-form-input
                  v-model="filter.name"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('name')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="ИНН">
                <b-form-input
                  v-model="filter.INN"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('INN')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Email">
                <b-form-input
                  v-model="filter.email"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('email')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Телефон">
                <b-form-input
                  v-model="filter.phone"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('phone')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Статус"
              >
                <el-select
                  v-model="filter.verificationStatus"
                  class="form-control"
                  name="verification-status"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('verificationStatus')"
                >
                  <el-option
                    v-for="item in verificationStatusesOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Кол-во водителей">
                <b-row>
                  <b-col md="6">
                    <b-form-input
                      v-model="filter.driversFrom"
                      type="number"
                      min="0"
                      autocomplete="false"
                      placeholder="От"
                      @input="updateFilter('driversFrom')"
                    />
                  </b-col>
                  <b-col md="6">
                    <b-form-input
                      v-model="filter.driversTo"
                      type="number"
                      min="0"
                      autocomplete="false"
                      placeholder="До"
                      @input="updateFilter('driversTo')"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Кол-во грузовиков">
                <b-row>
                  <b-col md="6">
                    <b-form-input
                      v-model="filter.trucksFrom"
                      type="number"
                      min="0"
                      autocomplete="false"
                      placeholder="От"
                      @input="updateFilter('trucksFrom')"
                    />
                  </b-col>
                  <b-col md="6">
                    <b-form-input
                      v-model="filter.trucksTo"
                      type="number"
                      min="0"
                      autocomplete="false"
                      placeholder="До"
                      @input="updateFilter('trucksTo')"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Кол-во прицепов">
                <b-row>
                  <b-col md="6">
                    <b-form-input
                      v-model="filter.trailersFrom"
                      type="number"
                      min="0"
                      autocomplete="false"
                      placeholder="От"
                      @input="updateFilter('trailersFrom')"
                    />
                  </b-col>
                  <b-col md="6">
                    <b-form-input
                      v-model="filter.trailersTo"
                      type="number"
                      min="0"
                      autocomplete="false"
                      placeholder="До"
                      @input="updateFilter('trailersTo')"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Рейтинг">
                <b-row>
                  <b-col md="6">
                    <b-form-input
                      v-model="filter.ratingFrom"
                      type="number"
                      min="0"
                      autocomplete="false"
                      placeholder="От"
                      @input="updateFilter('ratingFrom')"
                    />
                  </b-col>
                  <b-col md="6">
                    <b-form-input
                      v-model="filter.ratingTo"
                      type="number"
                      min="0"
                      autocomplete="false"
                      placeholder="До"
                      @input="updateFilter('ratingTo')"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Перевозчики
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="verificationStatus"
            slot-scope="props"
          >
            <span
              :class="{'text-secondary' : props.row.verificationStatus === 0, 'text-success' : props.row.verificationStatus === 1, 'text-warning' : props.row.verificationStatus === 2, 'text-danger' : props.row.verificationStatus === 3}"
            >{{ verificationStatusNames[props.row.verificationStatus] }}</span>
          </span>
          <span
            slot="contractor.phone"
            slot-scope="props"
          >
            <a
              :href="`tel:${props.row.contractor.phone}`"
              class="icon-email"
            >{{ props.row.contractor.phone }}</a>
          </span>
          <span
            slot="contractor.driversCount"
            slot-scope="props"
          >
            <span v-if="!props.row.contractor.driversCount">0</span>
            <span v-else>{{ props.row.contractor.driversCount }}</span>
          </span>
          <span
            slot="contractor.trucksCount"
            slot-scope="props"
          >
            <span v-if="!props.row.contractor.trucksCount">0</span>
            <span v-else>{{ props.row.contractor.trucksCount }}</span>
          </span>
          <span
            slot="contractor.trailersCount"
            slot-scope="props"
          >
            <span v-if="!props.row.contractor.trailersCount">0</span>
            <span v-else>{{ props.row.contractor.trailersCount }}</span>
          </span>
          <span
            slot="contractor.notificationEmail"
            slot-scope="props"
          >
            <a
              v-if="props.row.contractor.notificationEmail"
              :href="`mailto:${props.row.contractor.notificationEmail}`"
              class="icon-email"
            >{{ props.row.contractor.notificationEmail }}</a>
            <a
              v-else
              :href="`mailto:${props.row.contractor.email}`"
              class="icon-email"
            >{{ props.row.contractor.email }}</a>
          </span>
          <span
            slot="rating"
            slot-scope="props"
          >
            <a
              v-b-tooltip.hover.bottom="'Детализация рейтинга'"
              :href="'/customer/contractors-rating/id' + props.row.contractor.id"
            >
              <span
                v-if="props.row.rating"
                :class="{'rating-low': props.row.rating.rating <= 30, 'rating-mid': props.row.rating.rating >= 31 && props.row.rating.rating <= 49,
                         'rating-high': props.row.rating.rating >= 50}"
              >{{ props.row.rating.rating }}</span>
              <span v-else>Нет рейтинга</span>
            </a>
          </span>
          <span
            slot="expressDriverCheck"
            slot-scope="props"
          >
            <span
              v-if="props.row.expressDriverCheck"
              class="fa fa-check fa-lg text-success"
            />
            <span
              v-else
              class="fa fa-close fa-lg text-danger"
            />

          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="$store.getters.isAdmin"
              v-b-tooltip.hover.bottom="'Редактировать контактные реквизиты'"
              :href="'/contact-requisites/contractor/id'+props.row.contractor.id"
              class="fa fa-address-card fa-lg text-primary table-action-button"
            />
            <a
              v-if="$store.getters.isAdmin"
              v-b-tooltip.hover.bottom="'Изменить статус верификации'"
              :href="'/verification/contractor/id'+props.row.contractor.id"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="'/customer/contractors/id' + props.row.contractor.id"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {Event, ServerTable} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {customersContractorsList} from '../../services/api';
import {queryToUrl, URLToQuery} from '../../services/http';
import moment from 'moment';

Vue.use(ServerTable);

export default {
  name: 'ContractorList',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      filter: {
        name: null,
        verificationStatus: null,
        email: null,
        phone: null,
        driversFrom: null,
        driversTo: null,
        trucksFrom: null,
        trucksTo: null,
        trailersFrom: null,
        trailersTo: null,
        ratingFrom: null,
        ratingTo: null,
        INN: null,
        page: 1,
        limit: 10,
        valid: true,
      },
      verificationStatusesOptions: [
        {value: null, text: 'Все'},
        {value: 1, text: 'Принят'},
        {value: 0, text: 'Не принят'},
        {value: 2, text: 'На рассмотрении'},
        {value: 3, text: 'Отклонен'},
      ],
      verificationStatusNames: {
        1: 'Принят',
        0: 'Не принят',
        2: 'На рассмотрении',
        3: 'Отклонен',
      },
      loading: false,
      columns: [
        'contractor.name',
        'verificationStatus',
        'contractor.INN',
        'contractor.phone',
        'contractor.driversCount',
        'contractor.trucksCount',
        'contractor.trailersCount',
        'contractor.notificationEmail',
        'rating',
        'expressDriverCheck',
        'actions',
      ],
      data: [],
      options: {
        customFilters: [
          'name',
          'verificationStatus',
          'email',
          'phone',
          'driversFrom',
          'driversTo',
          'trucksFrom',
          'trucksTo',
          'trailersFrom',
          'trailersTo',
          'INN',
          'ratingFrom',
          'ratingTo',
          'valid',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          data.valid = true;
          return customersContractorsList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'contractor.name': 'Название',
          'verificationStatus': 'Статус верификации',
          'contractor.phone': 'Телефон',
          'contractor.driversCount': 'Кол-во водителей',
          'contractor.trucksCount': 'Кол-во грузовиков',
          'contractor.trailersCount': 'Кол-во прицепов',
          'contractor.notificationEmail': 'Email',
          'contractor.INN': 'ИНН',
          'rating': 'Рейтинг',
          'expressDriverCheck': 'Доступна экспресс проверка водителей',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    const queries = this.$router.currentRoute.query;
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    onSubmit() {
      for (const prop in this.filter) {
        const val = this.filter[prop] ? this.filter[prop] : null;
        Event.$emit('vue-tables.filter::' + prop, val);
      }
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.verificationStatus) {
        res.verificationStatus = Number(this.filter.verificationStatus);
      }
      if (this.filter.email) {
        res.email = this.filter.email;
      }
      if (this.filter.phone) {
        res.phone = this.filter.phone;
      }
      if (this.filter.driversFrom) {
        res.driversFrom = this.filter.driversFrom;
      }
      if (this.filter.driversTo) {
        res.driversTo = this.filter.driversTo;
      }
      if (this.filter.trucksFrom) {
        res.trucksFrom = this.filter.trucksFrom;
      }
      if (this.filter.trucksTo) {
        res.trucksTo = this.filter.trucksTo;
      }
      if (this.filter.trailersFrom) {
        res.trailersFrom = this.filter.trailersFrom;
      }
      if (this.filter.trailersTo) {
        res.trailersTo = this.filter.trailersTo;
      }
      if (this.filter.INN) {
        res.INN = this.filter.INN;
      }
      if (this.filter.name) {
        res.name = this.filter.name;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      if (this.filter.limit && this.filter.limit !== 10) {
        res.limit = this.filter.limit;
      } else {
        delete res.limit;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('verificationStatus')) {
          params.verificationStatus = Number(params.verificationStatus);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          Object.assign(this.filter, params);
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>
